<template>
  <div style="position: relative" class="w-100 d-flex flex-column">
    <loader-spinner no-border-radius-in-overlay centered v-if="isShowOverPageSpinner"></loader-spinner>
    <div class="d-flex w-100 flex-column justify-content-end align-items-end mb-1">
      <WButton info sm
        v-if="!isDisable"
        @click.stop.prevent="addButtonClickHandler"
        :disabled="isDisable"
      >
        {{ $t('SNMP.add') }}
      </WButton>
    </div>
    <div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>{{ $t('SNMP.SNMPUsers.userName') }}</th>
<!--            <th>{{ $t('SNMP.SNMPUsers.groupName') }}</th>-->
            <th v-if="showAllSettingsOnSNMPPartitionPages">{{ $t('SNMP.SNMPUsers.securityMode') }}</th>
            <th>{{ $t('SNMP.SNMPUsers.authenticationProtocol') }}</th>
            <th>{{ $t('SNMP.SNMPUsers.privacyProtocol') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.name" @click.stop.prevent="tableStringClickHandler(user)">
            <td>{{ user.user_name }}</td>
<!--            <td>{{ getUserGroupNameById(user.user_group) }}</td>-->
            <td v-if="showAllSettingsOnSNMPPartitionPages" >{{ getSecurityLevelsForDisplayWithI18n(user.security_level) }}</td>
            <td>{{ user.auth_protocol }}</td>
            <td>{{ user.privacy_protocol }}</td>
          </tr>
          <tr v-if="!users.length">
            <td colspan="4" class="no-data-row">
              <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <modal
      v-if="isModalOpen"
      :backdrop="false"
      :title="isNew ? this.$t('SNMP.SNMPUsers.addUsersModalHeader') : this.$t('SNMP.SNMPUsers.editUsersModalHeader')"
      class="modal-info"
      closeBtn
      effect="fade/zoom"
      v-model="isModalOpen"
    >
      <h4 slot="modal-title" class="modal-title">
        {{ isNew ? this.$t('SNMP.SNMPUsers.addUsersModalHeader') : this.$t('SNMP.SNMPUsers.editUsersModalHeader') }}
      </h4>
      <!--      name-->
      <div class="form-group">
        <label class="required-mark" for="snmp-user-name">{{ $t('SNMP.SNMPUsers.userName') }}:</label>
        <input
          data-vv-validate-on="none"
          @input="removeValidationErrorByName('snmp-user-name')"
          :data-vv-as="$t('SNMP.SNMPUsers.userName')"
          v-validate="'required'"
          type="text"
          :disabled="isDisable"
          v-model="updatedUser.user_name"
          name="snmp-user-name"
          id="snmp-user-name"
          :placeholder="$t('SNMP.SNMPUsers.userName')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-user-name') }"
        />
        <span v-show="errors.has('snmp-user-name')" class="help is-danger">
          {{ errors.first('snmp-user-name') }}
        </span>
      </div>
      <!--     group name -->
      <div class="form-group" v-if="showAllSettingsOnSNMPPartitionPages">
        <label class="required-mark" for="snmp-user-group">{{ $t('SNMP.SNMPUsers.groupName') }}:</label>
        <select
          :data-vv-as="$t('SNMP.SNMPUsers.groupName')"
          v-validate="'required'"
          data-vv-validate-on="input"
          type="text"
          :disabled="isDisable"
          v-model="updatedUser.user_group"
          name="snmp-user-group"
          id="snmp-user-group"
          :placeholder="$t('SNMP.SNMPUsers.groupName')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-user-group') }"
        >
<!--          <option key="notSelected" value="">-->
<!--            {{ $t('SNMP.notSelected') }}-->
<!--          </option>-->
          <option
            :key="selectedUserGroupForUpdatedUserWhenOpenEditModal"
            v-if="!isNew && !userGroups.find(item => item.id === selectedUserGroupForUpdatedUserWhenOpenEditModal)"
            :value="selectedUserGroupForUpdatedUserWhenOpenEditModal">
            {{ selectedUserGroupForUpdatedUserWhenOpenEditModal }}
          </option>
          <option :key="userGroup.id" v-for="userGroup in userGroupsSortedByName" :value="userGroup.id">
            {{ userGroup.name }}
          </option>
        </select>
        <span v-show="errors.has('snmp-user-group')" class="help is-danger">
          {{ errors.first('snmp-user-group') }}
        </span>
      </div>

      <!--     security mode-->
      <div class="form-group" v-if="showAllSettingsOnSNMPPartitionPages">
        <label class="required-mark" for="snmp-user-security-mode">{{ $t('SNMP.SNMPUsers.securityMode') }}:</label>
        <select
          :data-vv-as="$t('SNMP.SNMPUsers.securityMode')"
          v-validate="'required'"
          data-vv-validate-on="input"
          type="text"
          :disabled="isDisable"
          v-model="updatedUser.security_level"
          name="snmp-user-security-mode"
          id="snmp-user-security-mode"
          :placeholder="$t('SNMP.SNMPUsers.securityMode')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-user-security-mode') }"
        >
          <option :key="securityLevel" v-for="securityLevel in Object.values(securityLevels)" :value="securityLevel">
            {{ getSecurityLevelsForDisplayWithI18n (securityLevel) }}
          </option>
        </select>
        <span v-show="errors.has('snmp-user-security-mode')" class="help is-danger">
          {{ errors.first('snmp-user-security-mode') }}
        </span>
      </div>

      <!--     authentication Protocol  -->
      <div class="form-group">
        <label for="snmp-user-authentication-protocol">{{ $t('SNMP.SNMPUsers.authenticationProtocol') }}:</label>
        <select
          :data-vv-as="$t('SNMP.SNMPUsers.authenticationProtocol')"
          data-vv-validate-on="input"
          type="text"
          :disabled="isDisable"
          v-model="updatedUser.auth_protocol"
          name="snmp-user-authentication-protocol"
          id="snmp-user-authentication-protocol"
          :placeholder="$t('SNMP.SNMPUsers.authenticationProtocol')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-user-authentication-protocol') }"
        >
          <option
            :key="authenticationProtocol"
            v-for="authenticationProtocol in Object.values(authProtocols).filter(item=>isShowAuthProtocolOption(item))"
            :value="authenticationProtocol"
          >
            {{ authenticationProtocol }}
          </option>
        </select>
        <span v-show="errors.has('snmp-user-authentication-protocol')" class="help is-danger">
          {{ errors.first('snmp-user-authentication-protocol') }}
        </span>
      </div>

<!--      &lt;!&ndash;      authentication Password&ndash;&gt;-->
<!--      <div class="form-group">-->
<!--        <label class="required-mark" for="snmp-user-authentication-password">-->
<!--          {{ $t('SNMP.SNMPUsers.authenticationPassword') }}:-->
<!--        </label>-->
<!--        <input-->
<!--          data-vv-validate-on="none"-->
<!--          @input="removeValidationErrorByName('snmp-user-authentication-password')"-->
<!--          :data-vv-as="$t('SNMP.SNMPUsers.authenticationPassword')"-->
<!--          v-validate="'required'"-->
<!--          type="text"-->
<!--          :disabled="isDisable"-->
<!--          v-model="updatedUser.auth_pass_phrase"-->
<!--          name="snmp-user-authentication-password"-->
<!--          id="snmp-user-authentication-password"-->
<!--          :placeholder="$t('SNMP.SNMPUsers.authenticationPassword')"-->
<!--          class="form-control form-control-sm"-->
<!--          :class="{ input: true, 'is-danger': errors.has('snmp-user-authentication-password') }"-->
<!--        />-->
<!--        <span v-show="errors.has('snmp-user-authentication-password')" class="help is-danger">-->
<!--          {{ errors.first('snmp-user-authentication-password') }}-->
<!--        </span>-->
<!--      </div>-->

      <!--      authentication Password-->
      <div class="form-group">
        <label class="required-mark" for="snmp-user-authentication-password">
          {{ $t('SNMP.SNMPUsers.authenticationPassword') }}:
        </label>
        <div class="d-flex w-100">
          <div class="w-100">
            <input
              data-vv-validate-on="none"
              @input="removeValidationErrorByName('snmp-user-authentication-password')"
              :data-vv-as="$t('SNMP.SNMPUsers.authenticationPassword')"
              v-validate="'required'"
              :type="maskAuthPassPhrase ? 'password' : 'text'"
              :disabled="isDisable"
              v-model="updatedUser.auth_pass_phrase"
              name="snmp-user-authentication-password"
              id="snmp-user-authentication-password"
              ref="snmp-user-authentication-password"
              :placeholder="$t('SNMP.SNMPUsers.authenticationPassword')"
              class="form-control form-control-sm"
              :class="{ input: true, 'is-danger': errors.has('snmp-user-authentication-password') }"
            />
          </div>
          <div class="input-group-addon hovered form-control-sm"
               @click="maskAuthPassPhrase = !maskAuthPassPhrase">
            <i class="fa fa-eye" v-if="maskAuthPassPhrase"></i>
            <i class="fa fa-eye-slash" v-if="!maskAuthPassPhrase"></i>
          </div>
        </div>
        <span v-show="errors.has('snmp-user-authentication-password')" class="help is-danger">
          {{ errors.first('snmp-user-authentication-password') }}
        </span>
      </div>

            <div class="form-group">
        <label class="required-mark" for="snmp-user-authentication-password-сonfirmation">
          {{ $t('SNMP.SNMPUsers.authenticationPasswordConfirmation') }}:
        </label>
        <div class="d-flex w-100">
          <div class="w-100">
            <input
              data-vv-validate-on="none"
              @input="removeValidationErrorByName('snmp-user-authentication-password-сonfirmation')"
              :data-vv-as="$t('SNMP.SNMPUsers.authenticationPasswordConfirmation')"
              v-validate="'required|confirmed:snmp-user-authentication-password'"
              :type="maskAuthPassPhraseConfirmation ? 'password' : 'text'"
              :disabled="isDisable"
              v-model="authPassPhraseConfirmation"
              name="snmp-user-authentication-password-сonfirmation"
              id="snmp-user-authentication-password-сonfirmation"
              :placeholder="$t('SNMP.SNMPUsers.authenticationPassword')"
              class="form-control form-control-sm"
              :class="{ input: true, 'is-danger': errors.has('snmp-user-authentication-password-сonfirmation') }"
            />
          </div>
          <div class="input-group-addon hovered form-control-sm"
               @click="maskAuthPassPhraseConfirmation = !maskAuthPassPhraseConfirmation">
            <i class="fa fa-eye" v-if="maskAuthPassPhraseConfirmation"></i>
            <i class="fa fa-eye-slash" v-if="!maskAuthPassPhraseConfirmation"></i>
          </div>
        </div>
        <span v-show="errors.has('snmp-user-authentication-password-сonfirmation')" class="help is-danger">
          {{ errors.first('snmp-user-authentication-password-сonfirmation') }}
        </span>
      </div>



      <!--     privacy Protocol  -->
      <div class="form-group">
        <label for="snmp-user-privacy-protocol">{{ $t('SNMP.SNMPUsers.privacyProtocol') }}:</label>
        <select
          :data-vv-as="$t('SNMP.SNMPUsers.privacyProtocol')"
          data-vv-validate-on="input"
          type="text"
          :disabled="isDisable"
          v-model="updatedUser.privacy_protocol"
          name="snmp-user-privacy-protocol"
          id="snmp-user-privacy-protocol"
          :placeholder="$t('SNMP.SNMPUsers.privacyProtocol')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-user-privacy-protocol') }"
        >
          <option :key="privacyProtocol"
                  v-for="privacyProtocol in Object.values(privacyProtocols).filter(item=>isShowPrivacyProtocolOption(item))"
                  :value="privacyProtocol">
            {{ privacyProtocol }}
          </option>
        </select>
        <span v-show="errors.has('snmp-user-privacy-protocol')" class="help is-danger">
          {{ errors.first('snmp-user-privacy-protocol') }}
        </span>
      </div>

<!--      &lt;!&ndash;      privacy Password&ndash;&gt;-->
<!--      <div class="form-group">-->
<!--        <label class="required-mark" for="snmp-user-privacy-password">-->
<!--          {{ $t('SNMP.SNMPUsers.privacyPassword') }}:-->
<!--        </label>-->
<!--        <input-->
<!--          data-vv-validate-on="none"-->
<!--          @input="removeValidationErrorByName('snmp-user-privacy-password')"-->
<!--          :data-vv-as="$t('SNMP.SNMPUsers.privacyPassword')"-->
<!--          v-validate="'required'"-->
<!--          type="text"-->
<!--          :disabled="isDisable"-->
<!--          v-model="updatedUser.privacy_pass_phrase"-->
<!--          name="snmp-user-privacy-password"-->
<!--          id="snmp-user-privacy-password"-->
<!--          :placeholder="$t('SNMP.SNMPUsers.privacyPassword')"-->
<!--          class="form-control form-control-sm"-->
<!--          :class="{ input: true, 'is-danger': errors.has('snmp-user-privacy-password') }"-->
<!--        />-->
<!--        <span v-show="errors.has('snmp-user-privacy-password')" class="help is-danger">-->
<!--          {{ errors.first('snmp-user-privacy-password') }}-->
<!--        </span>-->
<!--      </div>-->

      <!--      privacy Password-->
      <div class="form-group">
        <label class="required-mark" for="snmp-user-privacy-password">
          {{ $t('SNMP.SNMPUsers.privacyPassword') }}:
        </label>
        <div class="d-flex w-100">
          <div class="w-100">
            <input
              data-vv-validate-on="none"
              @input="removeValidationErrorByName('snmp-user-privacy-password')"
              :data-vv-as="$t('SNMP.SNMPUsers.privacyPassword')"
              v-validate="'required'"
              :type="maskPrivacyPassPhrase ? 'password' : 'text'"
              :disabled="isDisable"
              v-model="updatedUser.privacy_pass_phrase"
              name="snmp-user-privacy-password"
              id="snmp-user-privacy-password"
              ref="snmp-user-privacy-password"
              :placeholder="$t('SNMP.SNMPUsers.privacyPassword')"
              class="form-control form-control-sm"
              :class="{ input: true, 'is-danger': errors.has('snmp-user-privacy-password') }"
            />
          </div>
          <div class="input-group-addon hovered form-control-sm"
               @click="maskPrivacyPassPhrase = !maskPrivacyPassPhrase">
            <i class="fa fa-eye" v-if="maskPrivacyPassPhrase"></i>
            <i class="fa fa-eye-slash" v-if="!maskPrivacyPassPhrase"></i>
          </div>
        </div>
        <span v-show="errors.has('snmp-user-privacy-password')" class="help is-danger">
          {{ errors.first('snmp-user-privacy-password') }}
        </span>
      </div>

      <div class="form-group">
        <label class="required-mark" for="snmp-user-privacy-password-confirmation">
          {{ $t('SNMP.SNMPUsers.privacyPasswordConfirmation') }}:
        </label>
        <div class="d-flex w-100">
          <div class="w-100">
            <input
              data-vv-validate-on="none"
              @input="removeValidationErrorByName('snmp-user-privacy-password-confirmation')"
              :data-vv-as="$t('SNMP.SNMPUsers.privacyPasswordConfirmation')"
              v-validate="'required|confirmed:snmp-user-privacy-password'"
              :type="maskPrivacyPassPhraseConfirmation ? 'password' : 'text'"
              :disabled="isDisable"
              v-model="privacyPassPhraseConfirmation"
              name="snmp-user-privacy-password-confirmation"
              id="snmp-user-privacy-password-confirmation"
              :placeholder="$t('SNMP.SNMPUsers.privacyPassword')"
              class="form-control form-control-sm"
              :class="{ input: true, 'is-danger': errors.has('snmp-user-privacy-password-confirmation') }"
            />
          </div>
          <div class="input-group-addon hovered form-control-sm"
               @click="maskPrivacyPassPhraseConfirmation = !maskPrivacyPassPhraseConfirmation">
            <i class="fa fa-eye" v-if="maskPrivacyPassPhraseConfirmation"></i>
            <i class="fa fa-eye-slash" v-if="!maskPrivacyPassPhraseConfirmation"></i>
          </div>
        </div>
        <span v-show="errors.has('snmp-user-privacy-password-confirmation')" class="help is-danger">
          {{ errors.first('snmp-user-privacy-password-confirmation') }}
        </span>
      </div>

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <WButton success outline
          customClass="mr-1"
          v-if="!isDisable"
          @click="modalSaveButtonHandler"
          :disabled="errors.any()"
        >
          {{ isNew ? $t('general.create') : $t('general.save') }}
        </WButton>
        <div>
          <WButton danger outline
            customClass="mr-2"
            v-if="!isNew && !isDisable"
            @click="isDeleteOpen = true"
          >
            {{ $t('general.delete') }}
          </WButton>
          <WButton secondary outline
            @click="modalCancelButtonHandler"
          >
            {{ $t('general.close') }}
          </WButton>
        </div>
      </div>
    </modal>

    <delete-dialog
      :is-open="isDeleteOpen"
      :handle-close="closeDeleteDialogHandler.bind(this)"
      :handle-delete="deleteDialogDeleteHandler.bind(this)"
      target-text=""
    />
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import DeleteDialog from '../../../../components/delete-dialog.vue';
import snmpUsersService from '../../../../services/Administration/SNMP/snmpUsersService';
import snmpUserGroupsService from '../../../../services/Administration/SNMP/snmpUserGroupsService';

const SECURITY_LEVELS = {
  NO_AUTH_NO_PRIV: 'noAuthNoPriv',
  AUTH_NO_PRIV: 'authNoPriv',
  AUTH_PRIV: 'authPriv',
};

const AUTH_PROTOCOLS = {
  MD5: 'MD5',
  SHA: 'SHA',
  SHA224: 'SHA-224',
  SHA256: 'SHA-256',
  SHA384: 'SHA-384',
  SHA512: 'SHA-512',
};

const PRIVACY_PROTOCOLS = {
  AES: 'AES',
  DES: 'DES',
  AES192: 'AES-192',
  AES256: 'AES-256'
};

export default {
  name: 'SNMPUsers',
  components: { Modal, DeleteDialog },
  data() {
    return {
      sort_by: {
        field: 'name',
        order: 1
      },
      maskPrivacyPassPhraseConfirmation: true,
      maskAuthPassPhraseConfirmation: true,
      maskAuthPassPhrase: true,
      maskPrivacyPassPhrase: true,
      isDeleteOpen: false,
      isModalOpen: false,
      isNew: false,
      userGroups: [
      ],
      users: [
      ],
      updatedUser: {},
      selectedUserGroupForUpdatedUserWhenOpenEditModal: '',
      privacyPassPhraseConfirmation: '',
      authPassPhraseConfirmation: '',
      newUser: {
        user_name: '',
        user_group: '',
        security_level: SECURITY_LEVELS.NO_AUTH_NO_PRIV,
        auth_protocol: AUTH_PROTOCOLS.MD5,
        auth_pass_phrase: '',
        privacy_protocol: PRIVACY_PROTOCOLS.DES,
        privacy_pass_phrase: ''
      }
    };
  },
  computed: {
    showAllSettingsOnSNMPPartitionPages() {
      // используется для задачи DF-77 чтобы временно скрыть нереализованные на бэке функции
      // в подразделах раздела SNMP
      return this.$store.state.showAllSettingsOnSNMPPartitionPages;
    },
    userGroupsSortedByName() {
      const userGroups = JSON.parse(JSON.stringify(this.userGroups))
      return userGroups.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    },
    securityLevels() {
      return SECURITY_LEVELS;
    },
    authProtocols() {
      return AUTH_PROTOCOLS;
    },
    privacyProtocols() {
      return PRIVACY_PROTOCOLS;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    isShowOverPageSpinner() {
      return this.$store.state.loadingSNMPUserGroupsRequestExecuting ||
        this.$store.state.loadingSNMPUsersRequestExecuting ||
        this.$store.state.updateSNMPUsersRequestExecuting ||
        this.$store.state.createSNMPUsersRequestExecuting ||
        this.$store.state.deleteSNMPUsersRequestExecuting;
    }
  },
  methods: {
    isShowAuthProtocolOption(optionName) {
      if (this.showAllSettingsOnSNMPPartitionPages){
        return true;
      }
      if (['SHA-224', 'SHA-256', 'SHA-384', 'SHA-512'].includes(optionName)) {
        return false;
      }
      return true;
    },
    isShowPrivacyProtocolOption(optionName) {
      if (this.showAllSettingsOnSNMPPartitionPages){
        return true;
      }
      if (['AES-192', 'AES-256'].includes(optionName)) {
        return false;
      }
      return true;
    },
    maskAllKeysFields() {
      this.maskAuthPassPhrase = true;
      this.maskPrivacyPassPhrase = true;
      this.maskPrivacyPassPhraseConfirmation = true;
      this.maskAuthPassPhraseConfirmation = true;
    },
    unmaskAllKeysFields() {
      this.maskAuthPassPhrase = false;
      this.maskPrivacyPassPhrase = false;
      this.maskPrivacyPassPhraseConfirmation = false;
      this.maskAuthPassPhraseConfirmation = false;
    },
    getUserGroupNameById(id) {
      try {
        const userGroupObject = this.userGroups.find((item) => {
          return item.id === id;
        })
        // console.log(userGroupObject.name)
        return userGroupObject.name
      } catch (e) {
        return id
      }
    },
    getSecurityLevelsForDisplayWithI18n(securityLevel) {
      try {
        if (securityLevel === SECURITY_LEVELS.NO_AUTH_NO_PRIV) {
          return this.$t('SNMP.SNMPUsers.securityLevels.noAuthNoPriv')
        } else if (securityLevel === SECURITY_LEVELS.AUTH_NO_PRIV) {
          return this.$t('SNMP.SNMPUsers.securityLevels.authNoPriv')
        } else if (securityLevel === SECURITY_LEVELS.AUTH_PRIV) {
          return this.$t('SNMP.SNMPUsers.securityLevels.authPriv')
        }
        return securityLevel
      } catch (e) {
        return securityLevel
      }
    },
    removeValidationErrorByName(errorName) {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has(errorName)) {
          this.errors.remove(errorName);
        }
      }
    },
    addButtonClickHandler() {
      // console.log('click add button');
      this.updatedUser = JSON.parse(JSON.stringify(this.newUser));
      this.privacyPassPhraseConfirmation = this.updatedUser.privacy_pass_phrase;
      this.authPassPhraseConfirmation = this.updatedUser.auth_pass_phrase;
      this.openNewModal();
    },
    tableStringClickHandler(user) {
      this.updatedUser = JSON.parse(JSON.stringify(user));
      this.selectedUserGroupForUpdatedUserWhenOpenEditModal = this.updatedUser.user_group;
      this.privacyPassPhraseConfirmation = this.updatedUser.privacy_pass_phrase;
      this.authPassPhraseConfirmation = this.updatedUser.auth_pass_phrase;
      this.openEditModal();
    },
    clearUpdated() {
      this.updatedUser = JSON.parse(JSON.stringify(this.newUser));
      this.selectedUserGroupForUpdatedUserWhenOpenEditModal = '';
    },
    closeModal() {
      this.isModalOpen = false;
    },
    openModal() {
      this.isModalOpen = true;
    },
    openNewModal() {
      this.isNew = true;
      this.unmaskAllKeysFields();
      this.openModal();
    },
    openEditModal() {
      this.isNew = false;
      this.maskAllKeysFields();
      this.openModal();
    },
    modalSaveButtonHandler() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            if (this.isNew) {
              // console.log('creating new');
              snmpUsersService.create(this);
            } else {
              // console.log('saving');
              snmpUsersService.update(this);
            }
            this.closeModal();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    modalCancelButtonHandler() {
      this.clearUpdated();
      this.closeModal();
    },
    modalDeleteBtuttonHandler() {},
    closeDeleteDialogHandler() {
      this.isDeleteOpen = false;
    },
    deleteDialogDeleteHandler() {
      snmpUsersService.delete(this);
      this.closeDeleteDialogHandler();
      this.closeModal();
    },
    getAll() {
      snmpUsersService.getAll(this);
    }
  },
  created() {
    snmpUserGroupsService.getAll(this);
    this.getAll()
  }
};
</script>

<style scoped></style>
