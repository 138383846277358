import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';

export default {

  getAll(ctx) {
    ctx.users = [];
    const params = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };

    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }

    if (ctx.sort_by !== undefined && typeof ctx.sort_by === 'object' && Object.keys(ctx.sort_by) !== 0) {
      params.sort_by = JSON.parse(JSON.stringify(ctx.sort_by))
    }

    ctx.$store.commit('loadingSNMPUsersBegin');
    Vue.axios.post(`${API_URL || ''}/api/snmp_management/users`, params).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log(response.data.data.itemslist);
          const listFromResponse = JSON.parse(JSON.stringify(response.data.data.itemslist));

          ctx.users = listFromResponse;
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('loadingSNMPUsersEnd');
      },
      (err) => {
        ctx.$store.commit('loadingSNMPUsersEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  },
  update(ctx) {
    ctx.$store.commit('updateSNMPUsersReqBegin');
    const updated = JSON.parse(JSON.stringify(ctx.updatedUser));
    const updatedgId = updated.id;

    const updateQuery = {
      action: 'U',
      items: { [updatedgId]: updated }
    };

    Vue.axios.post(`${API_URL || ''}/api/snmp_management/users`, updateQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('SNMP.SNMPUsers.notificationUpdatedSuccessfully') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            ctx.getAll();
          } else {
            ctx.getAll();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.closeModal();
        ctx.$store.commit('updateSNMPUsersReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('updateSNMPUsersReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  create(ctx) {
    ctx.$store.commit('createSNMPUsersReqBegin');
    const updated = JSON.parse(JSON.stringify(ctx.updatedUser));

    const updateQuery = {
      action: 'C',
      items: { 0: { ...updated } }
    };
    Vue.axios.post(`${API_URL || ''}/api/snmp_management/users`, updateQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('SNMP.SNMPUsers.notificationCreatedSuccessfully') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            // если в ответ прилетел новый объект то пытаемся его добавить, чтобы не запрашивать опять все объекты
            // this.addDitItemIntoExistingDitExportsList(ctx, response.data.data.itemslist[0]);
            ctx.getAll();
          } else {
            ctx.getAll();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.closeModal();
        ctx.$store.commit('createSNMPUsersReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('createSNMPUsersReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  delete(ctx) {
    ctx.$store.commit('deleteSNMPUsersReqBegin');
    const updated = JSON.parse(JSON.stringify(ctx.updatedUser));

    const updateQuery = {
      action: 'D',
      items: { [updated.id]: updated }
    };
    Vue.axios.post(`${API_URL || ''}/api/snmp_management/users`, updateQuery).then(
      (response) => {
        if (response.data.status === 'success' || response.status === 200) {
          VueNotifications.success({ message: ctx.$t('SNMP.SNMPUsers.notificationDeletedSuccessfully') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            ctx.getAll();
          } else {
            ctx.getAll();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.closeModal();
        ctx.$store.commit('deleteSNMPUsersReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('deleteSNMPUsersReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },


};
